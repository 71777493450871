import React, { FC, useRef, useEffect } from 'react'
import cn from 'classnames'

interface GoogleAutocompleteInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
    changePlace: (path: string, value: string) => void
    changePlaceFull?: (path: string, value: any) => void
    inputPath: string
    errorMessage?: string | undefined
    label: string
    wrapperClassName?: string
    id: string
}

declare global {
    interface Window {
        googleMapsScriptLoaded?: boolean
        googleMapsAPILoading?: boolean
    }
}
export function buildAddress({ street, zipCode, state, city, countryCode }: any) {
    const addressComponents: any = []
    if (street) {
        addressComponents.push(street)
    }

    if (city) {
        addressComponents.push(city)
    }

    if (state) {
        addressComponents.push(state + (zipCode ? ' ' + zipCode : ''))
    } else if (zipCode) {
        addressComponents.push(zipCode)
    }

    if (countryCode) {
        addressComponents.push(countryCode)
    }

    return addressComponents.join(', ').trim()
}

const GoogleAutocompleteInput: FC<GoogleAutocompleteInputProps> = ({
    changePlace,
    inputPath,
    errorMessage,
    changePlaceFull,
    wrapperClassName,
    label,
    id,
    ...rest
}) => {
    const locationRef = useRef<HTMLInputElement | null>(null)

    useEffect(() => {
        const googleApiKey = process.env.REACT_APP_GMAP_API_KEY

        const initializeAutocomplete = () => {
            const northAmericaBounds = new window.google.maps.LatLngBounds(
                new window.google.maps.LatLng(14.0, -169.0),
                new window.google.maps.LatLng(85.0, -52.0),
            )

            const locationInput = locationRef.current

            if (locationInput) {
                const autocomplete = new window.google.maps.places.Autocomplete(locationInput, {
                    bounds: northAmericaBounds,
                    strictBounds: true,
                    types: ['geocode'],
                    fields: ['address_components', 'geometry', 'icon', 'name'],
                })

                autocomplete.addListener('place_changed', () => {
                    const selectedPlace = autocomplete.getPlace()

                    if (selectedPlace && selectedPlace.address_components) {
                        const addressComponents = selectedPlace.address_components

                        const extractedAddress = {
                            zip: '',
                            street: '',
                            building: '',
                            city: '',
                            state: '',
                        }

                        addressComponents.forEach((component) => {
                            const types = component.types

                            if (types.includes('postal_code')) {
                                extractedAddress.zip = component.long_name
                            } else if (types.includes('route')) {
                                extractedAddress.street = component.long_name
                            } else if (types.includes('street_number')) {
                                extractedAddress.building = component.long_name
                            } else if (types.includes('locality')) {
                                extractedAddress.city = component.long_name
                            } else if (types.includes('administrative_area_level_1')) {
                                extractedAddress.state = component.short_name
                            }
                        })

                        const countryComponent = addressComponents.find((component) =>
                            component.types.includes('country'),
                        )
                        const country = countryComponent ? countryComponent.short_name : ''

                        const formattedAddress = buildAddress({
                            street:
                                (extractedAddress.building ? extractedAddress.building + ' ' : '') +
                                    extractedAddress.street ?? '',
                            zipCode: extractedAddress.zip,
                            state: extractedAddress.state,
                            city: extractedAddress.city,
                            countryCode: country,
                        })

                        locationRef.current!.value = formattedAddress

                        if (changePlace) {
                            changePlace(inputPath, formattedAddress)
                        }
                        if (changePlaceFull) {
                            changePlaceFull(inputPath, {
                                formattedAddress,
                                country,
                                zip: extractedAddress.zip,
                                street: extractedAddress.street,
                                building: extractedAddress.building,
                                city: extractedAddress.city,
                                state: extractedAddress.state,
                                lat: selectedPlace.geometry?.location?.lat?.(),
                                lng: selectedPlace.geometry?.location?.lng?.(),
                            })
                        }
                    }
                })
            }
        }

        const loadGoogleMapsAPI = () => {
            if (window.googleMapsScriptLoaded) {
                initializeAutocomplete()
                return
            }

            if (window.googleMapsAPILoading) {
                const checkScriptLoaded = setInterval(() => {
                    if (window.googleMapsScriptLoaded) {
                        clearInterval(checkScriptLoaded)
                        initializeAutocomplete()
                    }
                }, 100)
                return
            }

            window.googleMapsAPILoading = true

            const script = document.createElement('script')
            script.src = `https://maps.googleapis.com/maps/api/js?key=${googleApiKey}&libraries=places&language=en&v=weekly&callback=Function.prototype`
            script.async = true
            script.defer = true

            script.onload = () => {
                window.googleMapsAPILoading = false
                window.googleMapsScriptLoaded = true
                initializeAutocomplete()
            }

            document.head.appendChild(script)
        }

        if (!window?.google?.maps) {
            loadGoogleMapsAPI()
        } else {
            initializeAutocomplete()
        }
    }, [])

    const checkIfError = errorMessage ? 'input-error' : ''

    return (
        <div className={cn('input-location__wrap', wrapperClassName)}>
            <label className="input-location__label" htmlFor={`serviceLocation-${id}`}>
                {label}
            </label>
            <input
                ref={locationRef}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => changePlace(inputPath, e.target.value)}
                id={id}
                className={`input-location__input  ${checkIfError}`}
                {...rest}
            />
            {errorMessage && <span className="input-location__error-message input-label__error">{errorMessage}</span>}
        </div>
    )
}

export default GoogleAutocompleteInput
