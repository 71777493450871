import React, { FC, ReactNode, memo, useCallback, ChangeEvent } from 'react'
import cn from 'classnames'
import './Checkbox.scss'
import { Hint } from '../Hint'

interface ICheckbox {
    value: boolean
    label: ReactNode
    name?: string
    className?: string
    html?: React.ReactNode
    disabled?: boolean
    highlighted?: boolean
    onChange?: (e: ChangeEvent) => void
    errorMessage?: string
    hintText?: React.ReactNode
}

const Checkbox: FC<ICheckbox> = ({
    className,
    label,
    name,
    html,
    value,
    highlighted,
    disabled,
    hintText,
    onChange,
    errorMessage,
}) => {
    const handleChange = useCallback(
        (e) => {
            if (e.keyCode === 32 || !e.keyCode) {
                if (onChange) {
                    e.target.name = name
                    e.target.value = !value
                    onChange(e)
                }
            }
        },
        [name, value, onChange],
    )

    return (
        <div
            role="button"
            tabIndex={0}
            className={cn('checkbox', className, {
                active: value,
                highlighted,
                disabled: disabled && !value,
                isErrorMargin: errorMessage,
            })}
            onKeyUp={handleChange}
            onClick={handleChange}
        >
            <div className="control">
                <span className="icon-checkbox" />
            </div>

            <div>
                {(label || html || !!hintText) && (
                    <div className="text">
                        {label && <div className="label">{label}</div>}
                        {/* eslint-disable-next-line react/no-danger */}
                        {html && <div className="label">{html}</div>}
                        {!!hintText && <Hint type="right">{hintText}</Hint>}
                    </div>
                )}
                <input
                    hidden
                    type="checkbox"
                    readOnly
                    // value={value}
                    checked={value}
                />
            </div>
            {errorMessage && <p className="checkbox__error">{errorMessage}</p>}
        </div>
    )
}

export default memo(Checkbox)
