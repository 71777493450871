import { API_VERSION, isDevMode } from './utils/constants'

const localAPIPort = process.env.REACT_APP_LOCAL_API_PORT
const localAppUIPort = process.env.REACT_APP_LOCAL_UI_PORT
let originHostname = window.location.origin
if (isDevMode && localAPIPort) {
    originHostname = `${window.location.protocol}//${window.location.hostname}:${localAPIPort}`
}

const apiHostName = originHostname.replace('auth.', 'api.')
const wpHostName = originHostname.replace('auth.', '')
let appHostName = originHostname.replace('auth.', 'app.')

if (document.referrer.includes('localhost')) {
    appHostName = document.referrer
}

if (isDevMode) {
    console.log('Development mode')

    if (localAppUIPort) {
        appHostName = `${window.location.protocol}//${window.location.hostname}:${localAppUIPort}`
    }
}

export const URLs = Object.freeze({
    apiHostName,
    appHostName,
    wpHostName,
    baseApi: `${apiHostName}/api/${API_VERSION}`,
})

export const apiEndpoints = Object.freeze({
    resetPassword: `${URLs.baseApi}/sso/reset-password`,
})
