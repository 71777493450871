import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { SignUpPage } from '../pages/SignUpPage'
import { HomePage } from '../pages/HomePage'
import { VerificationPage } from '../pages/VerificationPage'
import { FindYourCompany } from '../pages/FindYourCompany'
import { RegisterCompany } from '../pages/RegisterCompany'
import { ROUTES } from '../router'
import { LoginPage } from '../pages/LoginPage'
import { ForgotPasswordPage } from 'pages/ForgotPasswordPage'
import { ForgotPasswordVerificationPage } from 'pages/ForgotPasswordVerificationPage'
import { ResetPasswordPage } from 'pages/ResetPasswordPage'
import { AccountTypeSelectionPage } from 'pages/AccountTypeSelectionPage'
import { ContactAdmin } from 'pages/ContactAdminPage'
import { CompanyExistingPage } from 'pages/CompanyExistingPage'
import { CompanyNotFoundPage } from 'pages/CompanyNotFoundPage'
import { EmailNotificationSettingsPage } from 'pages/EmailNotificationSettings'
import VerifyEmailPage from 'pages/VerifyEmailPage'
import { InvitePage } from 'pages/InvitePage'

export const MainRoute = ({ setTitle, navigateHandler, setShowProgress, showProgress, setShowBackButton }: any) => {
    return (
        <Routes>
            <Route
                path={'/'}
                element={
                    <HomePage setTitle={setTitle} navigateHandler={navigateHandler} setShowProgress={setShowProgress} />
                }
            />
            <Route
                path={ROUTES.company.selectionPage}
                element={
                    <AccountTypeSelectionPage
                        setTitle={setTitle}
                        navigateHandler={navigateHandler}
                        setShowProgress={setShowProgress}
                    />
                }
            />
            <Route
                path={ROUTES.company.yourInformation}
                element={
                    <SignUpPage
                        setTitle={setTitle}
                        navigateHandler={navigateHandler}
                        setShowBackButton={setShowBackButton}
                    />
                }
            />
            <Route
                path={ROUTES.existingCompany.yourInformation}
                element={
                    <SignUpPage
                        setTitle={setTitle}
                        navigateHandler={navigateHandler}
                        setShowBackButton={setShowBackButton}
                    />
                }
            />
            <Route
                path={ROUTES.verification}
                element={
                    <VerificationPage
                        setShowProgress={setShowProgress}
                        setTitle={setTitle}
                        navigateHandler={navigateHandler}
                        setShowBackButton={setShowBackButton}
                    />
                }
            />
            <Route
                path={ROUTES.existingCompany.base}
                element={<FindYourCompany setTitle={setTitle} navigateHandler={navigateHandler} />}
            />
            <Route
                path={ROUTES.existingCompany.existing}
                element={<CompanyExistingPage setTitle={setTitle} navigateHandler={navigateHandler} />}
            />
            <Route
                path={ROUTES.existingCompany.admin}
                element={<ContactAdmin setTitle={setTitle} navigateHandler={navigateHandler} />}
            />
            <Route
                path={ROUTES.existingCompany.companyNotFound}
                element={<CompanyNotFoundPage setTitle={setTitle} navigateHandler={navigateHandler} />}
            />
            <Route
                path={ROUTES.existingCompany.existingAdmin}
                element={<ContactAdmin setTitle={setTitle} navigateHandler={navigateHandler} />}
            />

            {/* <Route
                path={ROUTES.verificationWarehouse}
                element={<VerificationPage setTitle={setTitle} navigateHandler={navigateHandler} />}
            /> */}

            <Route
                path={ROUTES.company.base}
                element={<RegisterCompany setTitle={setTitle} navigateHandler={navigateHandler} />}
            />
            <Route
                path={ROUTES['forgot-password']}
                element={
                    <ForgotPasswordPage
                        setTitle={setTitle}
                        navigateHandler={navigateHandler}
                        setShowProgress={setShowProgress}
                    />
                }
            />
            <Route
                path={ROUTES.loginVerification}
                element={
                    <ForgotPasswordVerificationPage
                        setTitle={setTitle}
                        navigateHandler={navigateHandler}
                        setShowProgress={setShowProgress}
                    />
                }
            />
            <Route
                path={ROUTES['password-reset']}
                element={
                    <ResetPasswordPage
                        setTitle={setTitle}
                        navigateHandler={navigateHandler}
                        setShowProgress={setShowProgress}
                        setShowBackButton={setShowBackButton}
                    />
                }
            />
            <Route
                path={ROUTES.login}
                element={
                    <LoginPage
                        setShowProgress={setShowProgress}
                        setTitle={setTitle}
                        navigateHandler={navigateHandler}
                    />
                }
            />
            <Route
                path={ROUTES.company.verification}
                element={
                    <VerificationPage
                        setShowProgress={setShowProgress}
                        setTitle={setTitle}
                        navigateHandler={navigateHandler}
                        setShowBackButton={setShowBackButton}
                    />
                }
            />
            <Route
                path={ROUTES.existingCompany.verification}
                element={
                    <VerificationPage
                        setShowProgress={setShowProgress}
                        setTitle={setTitle}
                        navigateHandler={navigateHandler}
                        setShowBackButton={setShowBackButton}
                    />
                }
            />
            <Route path={ROUTES['verify-email']} element={<VerifyEmailPage setShowBackButton={setShowBackButton} />} />
            <Route
                path={`${ROUTES.invite}/:id`}
                element={
                    <InvitePage
                        setTitle={setTitle}
                        setShowProgress={setShowProgress}
                        navigateHandler={navigateHandler}
                        setShowBackButton={setShowBackButton}
                    />
                }
            />

            <Route
                path={`${ROUTES.emailNotifications}/*`}
                element={
                    <EmailNotificationSettingsPage
                        setTitle={setTitle}
                        navigateHandler={navigateHandler}
                        setShowProgress={setShowProgress}
                        setShowBackButton={setShowBackButton}
                    />
                }
            />
            <Route path="*" element={<Navigate replace to="/" />} />
        </Routes>
    )
}
