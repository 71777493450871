import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Button from '../components/UI/Button'
import { ROUTES } from 'router'
import { Company, LocalStorageNames } from 'types'

interface CompanyExistingPageProps {
    setTitle: (param: string) => void
    navigateHandler: (props: string) => void
}

export const CompanyExistingPage = ({ setTitle, navigateHandler }: CompanyExistingPageProps) => {
    const [companyName, setCompanyName] = useState<Company>()
    useEffect(() => {
        setTitle('We found your company')
        const getCompany = localStorage.getItem(LocalStorageNames.companyInfo)

        // get company from local store
        if (getCompany) {
            setCompanyName(JSON.parse(getCompany))
        }
    }, [])

    const handleSubmit = (e: any) => {
        navigateHandler(ROUTES.existingCompany.yourInformation)
    }

    return (
        <>
            <div className="sign-up__row">
                <input value={companyName?.name ?? ''} type="email" disabled className="sign-up__companyEmail" />
            </div>
            <Button
                // disabled={!formik.isValid}
                className="button"
                type="button"
                types={['blue', 'full']}
                onClick={(e) => handleSubmit(e)}
            >
                Next
            </Button>
            <Link
                to={`/${ROUTES.existingCompany.admin}`}
                className="link"
                style={{ textDecoration: 'underline', marginTop: 20, display: 'block' }}
            >
                Not your company
            </Link>
        </>
    )
}
