export enum IEntityType {
    carrier = 'carrier',
    warehouse = 'warehouse',
    'Shipper/Broker' = 'Shipper/Broker',
}

export type CompanyRole = 'Supply' | 'Demand'

export interface Company {
    id: string
    name: string
    type: CompanyRole
    existingId: string
    message?: string
}

export interface ErrorMessage {
    error: string
    message: string
    statusCode: number
}

export enum LocalStorageNames {
    companyEmail = 'companyEmail',
    token = 'token',
    companyName = 'companyName',
    inviteLink = 'inviteLink',
    companyLocation = 'newCompanyLocation',
    companyInfo = 'company',
    role = 'role',
    siteSlug = 'slug',
    signupCode = 'signupCode',
}

export enum WarehouseType {
    demand = 'Demand',
    supply = 'Supply',
}

export enum CompanyType {
    new = 'new',
    existing = 'existing',
}

export interface SignupCodeData {
    id: string
    firstName: string
    lastName: string
    email: string
    phone: string
    title: string
    location: string
    account: {
        id: string
        name: string
        type: CompanyRole
    }
}
export interface InviteLinkDataType {
    email: string
    contact: string
    account: {
        id: string
        name: string
        type: CompanyRole
    }
    invitedBy: {
        id: number
        name: string
    }
}
