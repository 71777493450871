import { URLs } from '../urls'

export const fetchDataBySignupCode = async (signupCode: string) => {
    return fetch(`${URLs.baseApi}/sso/code/${signupCode}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    })
}
