import React from 'react'
import { ReactComponent as ArrowRightIcon } from '../../../assets/icons/ic-arrow-next.svg'
import './SelectionCard.scss'

interface ISelectionCardProps {
    title: string
    content: string
    onClick?: (param?: any) => void
    key?: string
}

export const SelectionCard = ({ title, content, onClick, key }: ISelectionCardProps) => {
    return (
        <div className="selection-card" onClick={onClick} key={key}>
            <div className="selection-card__title">{title}</div>
            <div className="selection-card__content">{content}</div>
            <ArrowRightIcon className="selection-card__icon" />
        </div>
    )
}
