import useSWR from 'swr'
import { URLs } from '../urls'

export interface MicrositeData {
    accountId: string
    accountName: string
    aeCampaignId: string
    aeFormHandler: string
    clientName: string
    createdAt: string
    distributionMembers: string[]
    id: string
    notes: string
    olimpDistributionEmail: string
    olimpSupportPhone: string
    partnerCode: string
    partnerCodeName: string
    site: string
    updatedAt: string
}

export const useMicrosite = () => {
    const slugQueryParam = new URL(window.location.href).searchParams.get('slug')

    const respParams = useSWR<MicrositeData | null>(
        slugQueryParam ? '/api/v2/accounts/microsite/' + slugQueryParam : null,
        async (url) => {
            const response = await fetch(`${URLs.apiHostName}/api/v2/accounts/microsite/${slugQueryParam}`)
            return response.json().then((data) => {
                if (data.statusCode === 404) {
                    return null
                }
                return data as MicrositeData
            })
        },
        {
            revalidateOnFocus: false,
        },
    )
    return {
        ...respParams,
        slug: slugQueryParam,
    }
}
