import React from 'react'
import ToggleSwitch from 'components/UI/ToggleSwitch'
import Button from 'components/UI/Button'
import { FormikProps } from 'formik'
import { IEmailNotificationSettings } from './EmailNotificationFormContainer'
import { handleSubscribe } from './index'
interface IEmailNotificationFormProps {
    email: string
    token: string | null
    formik: FormikProps<IEmailNotificationSettings>
    type: string
}


const EmailNotificationForm: React.FC<IEmailNotificationFormProps> = ({ email, token, formik, type }) => {

    const  preferences = {
            chatEmailOptOut: !formik.values.chatEmailOptOut,
            marketingEmailOptOut: !formik.values.marketingEmailOptOut,
            warehousingEmailOptOut: !formik.values?.warehousingEmailOptOut,
    }
    const  handleSubmit = async () => {
        await handleSubscribe(
            token,
            preferences,
            'Preferences updated successfully',
            'Failed to update preferences',
            'Error updating preferences'
        )
    }

    return (
        <div>
            <div className="sign-up__row">
                <div>
                    Set preferences for <span className="font-semibold">{email}</span>
                </div>
            </div>
            <div className="sign-up__row">
                <ToggleSwitch
                    label="Chat Notifications"
                    description="Receive an email when you receive a new chat message"
                    initialChecked={formik.values.chatEmailOptOut}
                    onChange={(checked) => formik.setFieldValue('chatEmailOptOut', checked)}
                />
            </div>
            <div className="sign-up__row">
                <ToggleSwitch
                    label={type === 'Demand' ? 'Between the Dock Doors Monthly Newsletter' : 'Warehousing the Freight Monthly Newsletter' }
                    description="Stay up to date on all things OLIMP with our monthly newsletter"
                    initialChecked={formik.values.marketingEmailOptOut}
                    onChange={(checked) => formik.setFieldValue('marketingEmailOptOut', checked)}
                />
            </div>
            { type === 'Supply' ? <div className="sign-up__row">
                <ToggleSwitch
                    label="New Warehousing Opportunities"
                    description="Receive emails when warehousing opportunities are available near your warehouse"
                    initialChecked={formik.values.warehousingEmailOptOut}
                    onChange={(checked) => formik.setFieldValue('warehousingEmailOptOut', checked)}
                />
            </div> : null }
            <Button
                loading={formik.isSubmitting}
                className="button sign-up__btn"
                type="button"
                types={['blue', 'full']}
                disabled={formik.isSubmitting}
                onClick={handleSubmit}
            >
                Save Changes
            </Button>
            <div className="sign-up__row">
                <a href={`unsubscribe-all?token=${token}`}>Opt Out of All Email Communication (Unsubscribe)</a>
            </div>
        </div>
    )
}

export default EmailNotificationForm
