import React, { HTMLProps } from 'react'
import Tooltip from 'react-tooltip-lite'
import cn from 'classnames'
import './Hint.scss'

interface IHint extends HTMLProps<HTMLDivElement> {
    title?: string
    toggle?: React.ReactNode
    type?: string
    disabled?: boolean
    direction?: string
    tipContentClassName?: string
    children?: React.ReactNode
}

export const Hint: React.FC<IHint> = ({ title, toggle, direction, type, tipContentClassName, children }) => (
    <div className={cn('hint', type)}>
        {/* @ts-ignore */}
        <Tooltip
            direction={direction}
            content={children}
            tipContentClassName={cn('hint__tooltip', tipContentClassName)}
            distance={15}
            background="white"
            tipContentHover
        >
            {toggle || <span className="icon">i</span>}
        </Tooltip>
    </div>
)
