import { URLs } from '../urls'

export const fetchInviteLink = async (inviteId: string) => {
    return fetch(`${URLs.baseApi}/accounts/invites/${inviteId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    })
}
