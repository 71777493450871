import React, { memo } from 'react'

export const SignUpBanner = memo(({ carouselItems, currentItem }: any) => {
    return (
        <div className="sign-up__carousel">
            <img src={carouselItems[currentItem].image} className="sign-up__carousel__image" alt="Olimp logo" />
            <div className="sign-up__carousel__content">
                <h2 className="sign-up__carousel__title">{carouselItems[currentItem].title}</h2>
                <p className="sign-up__carousel__text">{carouselItems[currentItem].text}</p>
            </div>
        </div>
    )
})
