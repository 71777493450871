import { useEffect } from 'react'
import { useFormik } from 'formik'
import Button from 'components/UI/Button'
import InputLabel from 'components/UI/InputLabel'
import { ROUTES } from '../router'
import * as Yup from 'yup'
import { useParams } from 'react-router-dom'
import { URLs } from '../urls'
import { toast } from 'react-toastify'

interface INewPasswordPageProps {
    setTitle: (param: string) => void
    navigateHandler: (props: string) => void
    setShowProgress: (props: boolean) => void
    setShowBackButton?: any
}

export const newPasswordValidationSchema = Yup.object().shape({
    password: Yup.string()
        .required('Password is required')
        .min(7, 'Password must be at least 7 characters long')
        .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
        .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
        .matches(/[\d\W]/, 'Password must contain at least one number or special character'),
    repeatPassword: Yup.string()
        .required('Please confirm your password')
        .oneOf([Yup.ref('password'), ''], 'Passwords must match'),
})

export const ResetPasswordPage = ({
    setTitle,
    navigateHandler,
    setShowProgress,
    setShowBackButton,
}: INewPasswordPageProps) => {
    // get token from url
    const url = new URL(window.location.href)
    const token = url.searchParams.get('token')

    useEffect(() => {
        setTitle('New Password')
        setShowBackButton(false)
        setShowProgress(false)
    }, [])

    const formik = useFormik<{ password: string; repeatPassword: string }>({
        initialValues: {
            password: '',
            repeatPassword: '',
        },
        onSubmit: async (values) => {
            formik.setSubmitting(true)

            try {
                const response = await fetch(`${URLs.baseApi}/sso/reset-password`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        resetCode: token,
                        password: values.password,
                    }),
                })

                if (response.ok) {
                    toast('Password has been reset. Please Sign In!', { type: 'success' })
                    navigateHandler(ROUTES.login)
                }

                if (!response.ok) {
                    const data = await response.json()
                    console.log(data)

                    toast('Invalid reset link', { type: 'error' })
                }
            } catch (error: any) {
                toast(error?.resetCode, { type: 'error' })
                console.log(error)
            } finally {
                formik.setSubmitting(false)
            }
        },
        validateOnChange: true,
        validationSchema: newPasswordValidationSchema,
    })
    return (
        <>
            <div className="sign-up__row">
                <InputLabel
                    name="password"
                    className="sign-up__input"
                    value={formik.values.password}
                    errorMessage={formik.errors.password}
                    onChange={formik.handleChange}
                    label="Password*"
                    type="password"
                />
            </div>
            <div className="sign-up__row">
                <InputLabel
                    name="repeatPassword"
                    className="sign-up__input"
                    value={formik.values.repeatPassword}
                    errorMessage={formik.errors.repeatPassword}
                    onChange={formik.handleChange}
                    label="Repeat Password*"
                    type="password"
                />
            </div>
            <Button
                loading={formik.isSubmitting}
                className="button sign-up__btn"
                type="button"
                types={['blue', 'full']}
                disabled={!formik.isValid || formik.isSubmitting}
                onClick={() => formik.handleSubmit()}
            >
                Reset Password
            </Button>
        </>
    )
}
