import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import EmailNotificationForm from './EmailNotificationForm'

export interface IEmailNotificationFormContainerProps {
    email: string
    type: string
    token: string | null
    preferences: IEmailNotificationSettings
    onSuccess: () => void
}

export interface IEmailNotificationSettings {
    chatEmailOptOut: boolean,
    marketingEmailOptOut: boolean,
    warehousingEmailOptOut?: boolean,
}

const EmailNotificationFormContainer: React.FC<IEmailNotificationFormContainerProps> = ({ email, token, preferences, onSuccess, type }) => {
    const [formKey, setFormKey] = useState(0)

    useEffect(() => {
        setFormKey((prevKey) => prevKey + 1)
    }, [preferences])

    let preferenceForm = {
        chatEmailOptOut: !preferences.chatEmailOptOut,
        marketingEmailOptOut: !preferences.marketingEmailOptOut,
    }
    if (type === 'Supply') {
        Object.assign(preferenceForm, {warehousingEmailOptOut: !preferences?.warehousingEmailOptOut})
    }

    const formik = useFormik<IEmailNotificationSettings>({
        initialValues: preferenceForm,
        onSubmit: async () => {},
    })

    useEffect(() => {
        formik.setValues({...preferenceForm})
    }, [preferences])

    return (
        <div key={formKey}>
            <EmailNotificationForm token={token} email={email} formik={formik} type={type} />
        </div>
    )
}

export default EmailNotificationFormContainer
