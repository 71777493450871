import React, { useEffect, useState } from 'react'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import { URLs } from '../urls'
import { LocalStorageNames } from 'types'
import { toast } from 'react-toastify'
import { useCustomAnalyticsEvent } from 'hooks/useGoogleAnalytics'

interface IVerificationPageProps {
    setTitle: (param: string) => void
    navigateHandler: (props: string) => void
    setShowProgress: (param: boolean) => void
    setShowBackButton?: any
}

export const VerificationPage = ({
    setTitle,
    navigateHandler,
    setShowProgress,
    setShowBackButton,
}: IVerificationPageProps) => {
    const { sendCustomEvent } = useCustomAnalyticsEvent()
    const navigate = useNavigate()
    const timeoutRef = React.useRef<any>(null)
    const [email, setEmail] = useState(null)

    const refreshStatus = async () => {
        const token = localStorage.getItem(LocalStorageNames.token)

        try {
            const response = await fetch(`${URLs.baseApi}/sso/status`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            })

            if (response.ok) {
                const data = await response.json()
                if (data.verified) {
                    clearTimeout(timeoutRef.current)
                    localStorage.clear()
                    toast('Email verified', { type: 'success' })
                    window.location.href = `${URLs.appHostName}/auth/${data.token}`
                } else {
                    clearTimeout(timeoutRef.current)
                    timeoutRef.current = setTimeout(refreshStatus, 5000)
                    setEmail(data.email)
                }
            }
        } catch (error: any) {
            toast(error?.message ?? 'Something went wrong', { type: 'error' })
            console.log(error)
        }
    }

    useEffect(() => {
        setTitle('')
        setShowBackButton(false)
        setShowProgress(true)
        refreshStatus()
        return () => {
            clearTimeout(timeoutRef.current)
        }
    }, [])

    const logoutAndLogin = (e: any) => {
        e.preventDefault()
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current)
        }
        localStorage.clear()
        navigate('/login')
        sendCustomEvent('go_to_login_page_clicked')
    }

    const resendVerificationEmail = async (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault()
        const token = localStorage.getItem(LocalStorageNames.token)
        let success = false
        try {
            const response = await fetch(`${URLs.baseApi}/sso/resend-email-verification`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({
                    url: window.location.origin,
                }),
            })

            // get token
            // const data = await response.json()

            if (response.ok) {
                toast('We have sent verification link to your email', { type: 'success' })
            }
            success = response.ok
        } catch (error: any) {
            toast(error?.message ?? 'Something went wrong', { type: 'error' })
            console.log(error)
        } finally {
            sendCustomEvent('resend_verif_email_sent', {
                success: success,
            })
        }
    }
    const token = localStorage.getItem(LocalStorageNames.token)

    if (!token) {
        return <Navigate to={'/login'} />
    }

    return (
        <div className={'sign-up__row sign-up__row_mb'}>
            <div>
                <p className={'sign-up__verification__text'}> Check {email} for your verification link</p>
                <div>
                    <div>
                        Didn’t receive an email?{' '}
                        <Link to="#" className="link" onClick={resendVerificationEmail}>
                            Send Email Again
                        </Link>
                    </div>
                    <div>
                        Or email us at:{' '}
                        <a
                            className="link"
                            href="mailto:support@olimpwarehousing.com"
                            onClick={() => sendCustomEvent('support_email_clicked')}
                        >
                            support@olimpwarehousing.com
                        </a>
                    </div>
                    <div>
                        <br />
                        <br />
                        <a className="link" href="#" onClick={logoutAndLogin}>
                            Go to Login page
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}
