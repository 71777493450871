import React, { useRef, useEffect } from 'react'
import fitty from 'fitty'

interface FittyProps {
    minSize?: number
    maxSize?: number
    multiLine?: boolean
    className?: string
    children: React.ReactNode
}

const FittyText: React.FC<FittyProps> = ({ minSize = 12, maxSize = 30, multiLine = true, className, children }) => {
    const ref = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (ref.current) {
            fitty(ref.current, {
                minSize,
                maxSize,
                multiLine,
            })
        }
    }, [minSize, maxSize, multiLine])

    return (
        <div className={className} ref={ref}>
            {children}
        </div>
    )
}

export default FittyText
