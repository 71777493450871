import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { ROUTES } from 'router'
import { Loader } from 'components/UI/Loader'
import { URLs } from '../urls'
import Button from '../components/UI/Button'

function VerifyEmailPage({ setShowBackButton }: any) {
    const [verifyByButton, setVerifyByButton] = useState(false)
    const [message, setMessage] = useState(' Please complete verification process by clicking the button below')
    const [loading, setLoading] = useState(false)
    const [isError, setIsError] = useState(false)
    const navigate = useNavigate()

    const url = new URL(window.location.href)
    const queryToken = url.searchParams.get('token')

    const verify = () => {
        setIsError(false)
        setShowBackButton(false)
        setMessage('Verifying email...')
        setLoading(true)
        setTimeout(() => {
            if (!queryToken) {
                setMessage('Invalid verification code')
                setLoading(false)
                setShowBackButton(true)
                navigate(`/${ROUTES.login}`)
            } else {
                fetchData(queryToken)
            }
        }, 1000)
    }

    const fetchData = async (token: string) => {
        try {
            const response = await fetch(`${URLs.baseApi}/sso/email-verification`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ verificationCode: token }),
            })

            // get token
            const data = await response.json()
            // console.log('🚀 ~ fetchData ~ data:', data)

            if (response.ok) {
                toast('Success', { type: 'success' })
                // redirect to app login page
                window.location.href = `${URLs.appHostName}/auth/${data.token}`
            }

            if (!response.ok) {
                // console.log(data)
                setIsError(true)
                toast(data?.message, { type: 'error' })

                // invalid verificationCode
                if (data?.verificationCode) {
                    setLoading(false)
                    setMessage(data?.verificationCode)
                    return
                }
            }
        } catch (error: any) {
            console.log(error)
            toast(`${error?.message}`, { type: 'error' })
            navigate(`/${ROUTES.login}`)
        }
    }

    return (
        <div className="sign-up__verify">
            <div>
                <div
                    className={`sign-up__verify__title ${isError && 'sign-up__verify__error'}`}
                    style={{ marginBottom: 20 }}
                >
                    {message}
                    {!verifyByButton && (
                        <Button
                            types={['blue', 'small']}
                            style={{
                                whiteSpace: 'nowrap',
                            }}
                            onClick={() => {
                                setVerifyByButton(true)
                                verify()
                            }}
                        >
                            Verify Email
                        </Button>
                    )}
                </div>
                {loading && <Loader type="medium" />}
            </div>
        </div>
    )
}

export default VerifyEmailPage
