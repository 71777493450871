import React, { FC, useEffect, useRef } from 'react'
import intlTelInput, { Iti } from 'intl-tel-input'
import Inputmask from 'inputmask'

import 'intl-tel-input/build/css/intlTelInput.css'
import './PhoneInput.scss'

interface PhoneInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
    id: string
    label: string
    errorMessage?: string
    validatePhone?: () => boolean
    itiRef: React.MutableRefObject<Iti | null>
    wrapperRef?: React.Ref<HTMLDivElement>
    value?: string
    changeValue?: (value: string) => void
}

const PhoneInput: FC<PhoneInputProps> = ({
    errorMessage,
    label,
    id,
    itiRef,
    validatePhone,
    wrapperRef,
    value,
    changeValue,
    className,
    ...rest
}) => {
    const inputRef = useRef<HTMLInputElement | null>(null)

    const updateFn = () => {
        if (changeValue) {
            changeValue(itiRef.current?.getNumber(0) || '')
        }
    }

    useEffect(() => {
        const inputRefCurrent = inputRef.current
        if (inputRefCurrent) {
            itiRef.current = intlTelInput(inputRefCurrent, {
                utilsScript: 'https://cdn.jsdelivr.net/npm/intl-tel-input@18.2.1/build/js/utils.js',
                preferredCountries: ['us', 'ca', 'mx'],
                nationalMode: false,
                separateDialCode: true,
            })

            Inputmask({ mask: '(999) 999-9999', placeholder: '' }).mask(inputRefCurrent)

            inputRefCurrent.addEventListener('countrychange', updateFn)

            return () => {
                inputRefCurrent.removeEventListener('countrychange', updateFn)
                itiRef.current?.destroy()
            }
        }
    }, [])

    const checkIfError = errorMessage ? 'input-error' : ''

    return (
        <div className={className + ' input-wrapper iti-wrapper'} ref={wrapperRef}>
            <label htmlFor={id}>{label}</label>
            <input
                id={id}
                ref={inputRef}
                type="tel"
                className={checkIfError}
                onInput={updateFn}
                onBlur={validatePhone}
                {...rest}
            />
            {errorMessage && <span className="error-message">{errorMessage}</span>}
        </div>
    )
}

export default PhoneInput
