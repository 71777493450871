import { apiEndpoints } from '../urls'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { LocalStorageNames } from 'types'

interface IForgotPasswordVerificationPageProps {
    setTitle: (param: string) => void
    navigateHandler: (props: string) => void
    setShowProgress: (props: boolean) => void
}

export const ForgotPasswordVerificationPage = ({
    setTitle,
    navigateHandler,
    setShowProgress,
}: IForgotPasswordVerificationPageProps) => {
    const [email, setEmail] = useState<string>()

    useEffect(() => {
        setTitle('')
        setShowProgress(false)
    }, [])

    // set email
    useEffect(() => {
        const getEmail = localStorage.getItem(LocalStorageNames.companyEmail)
        if (getEmail) {
            setEmail(JSON.parse(getEmail))
        }
    }, [])

    const fetchReset = async (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault()

        try {
            const response = await fetch(apiEndpoints.resetPassword, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    url: window.location.origin,
                    email: email,
                }),
            })

            if (response.ok) {
                toast('Successfully sent', { type: 'success' })
            }

            if (!response.ok) {
                toast('Something went wrong', { type: 'error' })
            }
        } catch (error: any) {
            console.log(error)
            toast(error?.message ?? 'Something went wrong', { type: 'error' })
        }
    }

    return (
        <div className={'sign-up__row'}>
            <div>
                <p className={'sign-up__verification__text'}>We have sent a reset link to {email ?? 'your email'}</p>
                <div>
                    <span> Didn’t receive a link? </span>
                    <Link className={'link'} to={'#'} onClick={fetchReset}>
                        Send Link Again
                    </Link>
                </div>
            </div>
        </div>
    )
}
