import React from 'react'
import { SignUpPageList } from '../components/SignUpPageList'

interface ISignUpPageProps {
    setTitle?: any
    navigateHandler: (props: string) => void
    setShowBackButton?: (props: boolean) => void
}

export const SignUpPage = ({ navigateHandler, setTitle, setShowBackButton }: ISignUpPageProps) => {
    return (
        <SignUpPageList navigateHandler={navigateHandler} setTitle={setTitle} setShowBackButton={setShowBackButton} />
    )
}
