import React, { useEffect } from 'react'

interface IContactAdminProps {
    setTitle: (param: string) => void
    navigateHandler: (props: string) => void
}

export const ContactAdmin = ({ setTitle, navigateHandler }: IContactAdminProps) => {
    useEffect(() => {
        setTitle('Contact Admin')
    }, [])

    return (
        <>
            <div className="sign-up__row sign-up__row_mb">
                <p className="sign-up__text" style={{ margin: 0 }}>
                    Please contact your company’s portal admin to finish creating your account
                </p>
            </div>
        </>
    )
}
