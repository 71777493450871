import React, { useEffect, useState, useRef } from 'react'
import ReactGA from 'react-ga4'
import { useLocation } from 'react-router-dom'
import { useMicrosite } from '../api/useMicrosite'
import { isProdEnv } from '../utils/helper'

let isGA4Initialized = false
export const useGoogleAnalytics = () => {
    const { additionalParams } = useCustomAnalyticsEvent()
    const ga4MeasurementId = isProdEnv ? process.env.REACT_APP_GA4_PROD_ID : process.env.REACT_APP_GA4_ID

    useEffect(() => {
        if (!isGA4Initialized && ga4MeasurementId) {
            if (isProdEnv) {
                ReactGA.initialize(ga4MeasurementId || '')
            } else {
                ReactGA.initialize(ga4MeasurementId || '', {
                    gaOptions: {
                        debug_mode: true,
                    },
                    gtagOptions: {
                        debug_mode: true,
                    },
                })
            }
            isGA4Initialized = true
            console.warn('GA4 initialized')
        }
    }, [ga4MeasurementId])

    useEffect(() => {
        if (ga4MeasurementId) {
            ReactGA.set(additionalParams)
        }
    }, [additionalParams, ga4MeasurementId])

    useScrollTracking()

    return { isGA4Initialized }
}

export const useCustomAnalyticsEvent = () => {
    const { data: micrositeData, slug } = useMicrosite()
    const location = useLocation()
    const [additionalParams, setAdditionalParams] = useState({})

    useEffect(() => {
        const category = 'SSO'
        const analyticsUserInfo: { [key: string]: any } = {
            ...(category && { category }),
        }

        if (micrositeData?.id && slug) {
            analyticsUserInfo.accountType = 'supply'
            analyticsUserInfo.accountName = micrositeData.accountName
            analyticsUserInfo.accountId = micrositeData.accountId
            analyticsUserInfo.site = micrositeData.site
        }

        const params = {
            page_title: document.title,
            page_location: window.location.href,
            page: location.pathname + location.search,
        }

        setAdditionalParams(params)
    }, [location.pathname, location.search, micrositeData, slug])

    const sendCustomEvent = (eventName = '', additionalOptions = {}) => {
        const formattedEventName = eventName
            .replace(/[\s\W]+/g, '_')
            .toLowerCase()
            .trim()

        if (!isGA4Initialized) {
            console.error('Google Analytics Measurement ID is not installed. Event not sent:', formattedEventName)
            return
        }

        ReactGA.event(formattedEventName, { ...additionalOptions, ...additionalParams })
    }

    type HandleMenuClickProps = {
        label: string
        link?: string
        callBack?: () => void
        place?: string
    }

    const sendMenuClick = ({ label, link, callBack, place }: HandleMenuClickProps) => {
        sendCustomEvent('menu_clicked', {
            menu_label: label,
            ...(link && { menu_link: link }),
            ...(place && { menu_place: place }),
        })

        if (callBack) {
            callBack()
        }
    }

    return { sendCustomEvent, additionalParams, sendMenuClick }
}

interface TrackedDepths {
    [key: string]: number[]
}

export const useScrollTracking = (): void => {
    const location = useLocation()
    const trackedDepthsRef = useRef<TrackedDepths>({})
    const appRef = useRef<HTMLElement | null>(null)

    useEffect(() => {
        appRef.current = document.getElementById('app')
    }, [])

    useEffect(() => {
        if (!isGA4Initialized) return

        const trackScrollEvent = () => {
            if (appRef.current) {
                const scrollPercent = Math.round(calculateScrolledPercentage(appRef.current))
                const currentPathDepths = trackedDepthsRef.current[location.pathname] || []
                const alreadyTracked = currentPathDepths.includes(scrollPercent)

                if ([20, 50, 75, 90].includes(scrollPercent) && !alreadyTracked) {
                    ReactGA.event({
                        category: 'scroll',
                        action: 'scroll_depth',
                        label: `${scrollPercent}% Page Scroll`,
                        value: scrollPercent,
                        nonInteraction: true,
                    })

                    trackedDepthsRef.current = {
                        ...trackedDepthsRef.current,
                        [location.pathname]: [...currentPathDepths, scrollPercent],
                    }
                }
            }
        }

        window.addEventListener('scroll', trackScrollEvent, true)

        return () => {
            window.removeEventListener('scroll', trackScrollEvent)
        }
    }, [location.pathname])
}

function calculateScrolledPercentage(element: HTMLElement): number {
    const scrollTop = element.scrollTop
    const scrollHeight = element.scrollHeight
    const clientHeight = element.clientHeight
    const scrolledPercentage = (scrollTop / (scrollHeight - clientHeight)) * 100

    return scrolledPercentage
}
