import React, { useState } from 'react';
import './ToggleSwitch.scss';

interface ToggleSwitchProps {
    label: string;
    description: string;
    initialChecked?: boolean;
    onChange?: (checked: boolean) => void;
}

const ToggleSwitch: React.FC<ToggleSwitchProps> = ({ label, description, initialChecked = false, onChange }) => {
    const [checked, setChecked] = useState(initialChecked);

    const handleToggle = () => {
        setChecked(!checked);
        if (onChange) {
            onChange(!checked);
        }
    };

    return (
        <div className="toggle-switch">
            <label className="toggle-switch__label">
                <input
                    type="checkbox"
                    className="toggle-switch__input"
                    checked={checked}
                    onChange={handleToggle}
                />
                <span className="toggle-switch__slider"></span>
            </label>
            <div className={!checked ? 'toggle-switch__text unchecked' : 'toggle-switch__text'}>
                <strong>{label}</strong>
                <p>{description}</p>
            </div>
        </div>
    );
};

export default ToggleSwitch;
