import React from 'react'
import cn from 'classnames'
import './Loader.scss'

interface ILoader {
    type?: string
    className?: string
}

export const Loader: React.FC<ILoader> = ({ type, className }) => (
    <div className={cn('loader', type, className)}>
        <div className="spinner" />
    </div>
)
