export const getOLIMPLandingURL = (subPath?: string) => {
    const hostName = window.location.hostname
    let landingHostName = ''

    let count = 0
    for (let i = 0; i < hostName.length; i++) {
        if (hostName[i] === '.') {
            count++
        }
    }

    const firstDotIndex = window.location.hostname.indexOf('.')
    if (count > 1) {
        landingHostName = hostName.substring(firstDotIndex + 1)
    } else {
        landingHostName = hostName
    }

    return `${window.location.protocol}//${landingHostName}${subPath ? `/${subPath}` : ''}`
}
