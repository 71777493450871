export default function decodeToken(token: string) {
    try {
        const base64Url = token.split('.')[1]
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
        const jsonPayload = decodeURIComponent(
            window
                .atob(base64)
                .split('')
                .map(function (c) {
                    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
                })
                .join(''),
        )

        const payload = JSON.parse(jsonPayload)
        if (payload) {
            const expirationTime = payload.exp * 1000 // Convert expiration time to milliseconds
            const currentTime = Date.now()
            const expired = expirationTime < currentTime

            const expiresInSec = (expirationTime - currentTime) / 1000

            // console.log('Token will expire in ' + expiresInSec + ' seconds')

            return { expired, data: payload, expiresInSec, expirationTime }
        } else {
            return { expired: true }
        }
    } catch (e) {
        return { expired: true, data: null, expiresInSec: 0 }
    }
}
