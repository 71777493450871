import React, { createContext, useState, useContext, ReactNode } from 'react'
import { Company } from 'types'

type CompanyContextType = {
    company: Company | null
    setCompany: (company: Company) => void
}

const CompanyContext = createContext<CompanyContextType | undefined>(undefined)

interface CompanyProviderProps {
    children: ReactNode
}

export const CompanyProvider: React.FC<CompanyProviderProps> = ({ children }) => {
    const [company, setCompany] = useState<Company | null>(null)

    return <CompanyContext.Provider value={{ company, setCompany }}>{children}</CompanyContext.Provider>
}

export const useCompany = (): CompanyContextType => {
    const context = useContext(CompanyContext)
    if (context === undefined) {
        throw new Error('useCompany must be used within a CompanyProvider')
    }
    return context
}
