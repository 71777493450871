import React, { FC, ImgHTMLAttributes, useEffect, useState } from 'react'
import { Loader } from './UI/Loader'
import FittyText from 'utils/fiftyText'

interface WrapperProps<T> {
    WrapperComponent?: FC<T>
    wrapperComponentProps?: T
    resetTrigger?: {
        shouldReset: boolean
        setShouldReset: (value: boolean) => void
    }
    fallbackText?: string
}

type ConditionalImageProps<T> = ImgHTMLAttributes<HTMLImageElement> & WrapperProps<T>

const ConditionalImage = <T,>({
    WrapperComponent,
    src,
    alt,
    wrapperComponentProps,
    resetTrigger,
    fallbackText,
    ...props
}: ConditionalImageProps<T>) => {
    const [loading, setLoading] = useState(true)
    const [imgSrc, setImgSrc] = useState(src + `?${Date.now()}`)

    const handleError = () => {
        setImgSrc('')
        setLoading(false)
        resetTrigger?.setShouldReset?.(false)
    }

    const handleLoaded = () => {
        setLoading(false)
        resetTrigger?.setShouldReset?.(false)
    }

    useEffect(() => {
        if (resetTrigger?.shouldReset) {
            setLoading(true)
            setImgSrc(src + `?${Date.now()}`)
        }
    }, [resetTrigger?.shouldReset, src])

    return (
        <div className="sign-up__companyLogo">
            {imgSrc ? (
                <>
                    <img
                        src={imgSrc}
                        style={{ visibility: loading ? 'hidden' : 'visible' }}
                        alt={alt}
                        onError={handleError}
                        onLoad={handleLoaded}
                        {...props}
                    />
                    {loading && (
                        <div>
                            <Loader type="medium" />
                        </div>
                    )}
                </>
            ) : (
                fallbackText && (
                    <FittyText className="sign-up__companyTitle" minSize={12} maxSize={20}>
                        {fallbackText}
                    </FittyText>
                )
            )}
        </div>
    )
}

export default ConditionalImage
