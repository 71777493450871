import React, { memo, useEffect } from 'react'
import { SelectionCard } from '../components/UI/SelectionCard'
import { ROUTES } from '../router'
import { Link } from 'react-router-dom'
import { useMicrosite } from '../api/useMicrosite'
import { useCustomAnalyticsEvent } from 'hooks/useGoogleAnalytics'

interface IHomePageProps {
    setTitle?: any
    setStep?: any
    navigateHandler: (props: string) => void
    setShowProgress: (param: boolean) => void
}

export const HomePage = memo(({ setTitle, navigateHandler, setShowProgress }: IHomePageProps) => {
    const { sendCustomEvent } = useCustomAnalyticsEvent()
    const { slug } = useMicrosite()

    useEffect(() => {
        setTitle('What would you like to do?')
        setShowProgress(true)
    }, [])

    useEffect(() => {
        if (slug) {
        }
    }, [slug])

    return (
        <>
            <div style={{ marginBottom: 12 }}>
                <SelectionCard
                    title={'Register a new company'}
                    content={'Add your company to the OLIMP Network'}
                    onClick={() => {
                        navigateHandler(ROUTES.company.base)
                        sendCustomEvent('reqister_new_company_clicked')
                    }}
                />
            </div>
            <SelectionCard
                title={'Find my company'}
                content={'Join your company that already exists in the OLIMP Network'}
                onClick={() => {
                    navigateHandler(ROUTES.existingCompany.base)
                    sendCustomEvent('find_my_company_clicked')
                }}
            />
            <div className="sign-up__signInLink">
                <span className="sign-up__link">Already have an account?</span>
                <Link
                    className="link"
                    to={ROUTES.login}
                    onClick={() => {
                        sendCustomEvent('sign_in_clicked')
                    }}
                >
                    {' '}
                    Sign In{' '}
                </Link>
            </div>
        </>
    )
})
