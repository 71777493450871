import { useEffect, useRef } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { ROUTES } from '../router'
import InputLabel from 'components/UI/InputLabel'
import Button from 'components/UI/Button'
import { apiEndpoints } from '../urls'
import { toast } from 'react-toastify'
import { LocalStorageNames } from 'types'
import { useCustomAnalyticsEvent } from 'hooks/useGoogleAnalytics'

interface IForgotPasswordPageProps {
    setTitle: (param: string) => void
    navigateHandler: (props: string) => void
    setShowProgress: (props: boolean) => void
}

const forgotPasswordValidationSchema = Yup.object().shape({
    email: Yup.string().email('Company email must be a valid email').required('Company email is required field'),
})

export const ForgotPasswordPage = ({ setTitle, navigateHandler, setShowProgress }: IForgotPasswordPageProps) => {
    const { sendCustomEvent } = useCustomAnalyticsEvent()

    useEffect(() => {
        setTitle('Forgot Password')
        setShowProgress(false)
    }, [])

    const formik = useFormik<{ email: string }>({
        initialValues: {
            email: '',
        },
        validateOnChange: true,
        validationSchema: forgotPasswordValidationSchema,
        onSubmit: async (values) => {
            // console.log('val:', values)
            let error = false
            try {
                localStorage.setItem(LocalStorageNames.companyEmail, JSON.stringify(values.email))

                const response = await fetch(apiEndpoints.resetPassword, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        url: window.location.origin,
                        email: values.email,
                    }),
                })

                if (response.ok) {
                    toast('We have sent password reset link to your email', { type: 'success' })
                    navigateHandler(ROUTES.loginVerification)
                }

                if (!response.ok) {
                    const errorData = await response.json()
                    console.log(errorData)

                    toast(errorData?.email, { type: 'error' })
                    error = errorData?.email
                }
            } catch (error: any) {
                console.log('Error: ', error)
                if (error?.message) {
                    toast(error?.message, { type: 'error' })
                    formik.setErrors({ email: error?.message })
                }
            } finally {
                sendCustomEvent('forgot_password_form', {
                    success: error ? true : false,
                    errorMessage: error,
                })
            }
        },
    })

    const formikRef = useRef<any>(null)
    useEffect(() => {
        formikRef.current = formik
    }, [formik])

    return (
        <>
            <div className="sign-up__row">
                <InputLabel
                    name="email"
                    className="sign-up__input sign-up__input_p"
                    value={formik.values.email}
                    errorMessage={formik.errors.email}
                    onChange={formik.handleChange}
                    label="Email*"
                    onBlur={(e) => {
                        const fieldName = e.target.name
                        sendCustomEvent(`${fieldName}_field`, {
                            errorMessage: formikRef.current.errors[fieldName] || '',
                        })
                    }}
                />
            </div>
            <Button
                className="button sign-up__btn"
                type="button"
                loading={formik.isSubmitting}
                types={['blue', 'full']}
                disabled={!formik.isValid || formik.isSubmitting}
                onClick={() => formik.handleSubmit()}
            >
                Send Link
            </Button>
        </>
    )
}
